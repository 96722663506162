import {
  getDashboardLayout,
  getRealtimeData,
  getRealtimeSingleFrameData,
  getRealtimeSingleFrameGCData,
  getSCData,
  getLRData,
  getSafieListDevice,
  getRealtimeSingleFrameSCSData,
  createRealtimeSingleFrameSCSToken,
  createUserShare,
  updateUserShare,
  verifyTokenUserShare
} from "@/api/dashboard";
import axios from "axios";
import { setToken } from "@/utils/auth";
import {CONSTANTS} from "@/utils/constant";

const actions = {
  getRealtimeData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getRealtimeData(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRealtimeSingleFrameData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getRealtimeSingleFrameData(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRealtimeSingleFrameGCData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getRealtimeSingleFrameGCData(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRealtimeSingleFrameSCSData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      // getRealtimeSingleFrameSCSData(data)
      //   .then(response => {
      //     const data = response.data;
      //
      //     resolve(data); // resolve
      //   })
      //   .catch(error => {
      //     reject(error);
      //   });
      axios.get(`https://videocallserver.kns-cloud.com/openvidu/api/sessions/${data}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Basic T1BFTlZJRFVBUFA6MTIzNDU2"
        },
      })
        .then(response=>{
          resolve(response.data)
        })
        .catch(error=>{
          reject(error)
        })
    });
  },
  createRealtimeSingleFrameSCSData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      // getRealtimeSingleFrameSCSData(data)
      //   .then(response => {
      //     const data = response.data;
      //
      //     resolve(data); // resolve
      //   })
      //   .catch(error => {
      //     reject(error);
      //   });
      axios.post(`https://videocallserver.kns-cloud.com/openvidu/api/sessions/`,
        {
          mediaMode: "ROUTED",
          recordingMode: "MANUAL",
          customSessionId: data,
          forcedVideoCodec: "VP8",
          allowTranscoding: false,
          defaultRecordingProperties: {
            name: "MyRecording",
            hasAudio: true,
            hasVideo: true,
            outputMode: "COMPOSED",
            recordingLayout: "BEST_FIT",
            resolution: "1280x720",
            frameRate: 25,
            shmSize: 536870912,
            mediaNode: {
              id: "media_i-0c58bcdd26l11d0sd"
            }
          },
          mediaNode: {
            id: "media_i-0c58bcdd26l11d0sd"
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "Basic T1BFTlZJRFVBUFA6MTIzNDU2"
          },
        }
      )
        .then(response=>{
          resolve(response.data)
        })
        .catch(error=>{
          reject(error)
        })
    });
  },
  createRealtimeSingleFrameSCSToken({commit, state}, data) {
    return new Promise((resolve, reject) => {
      // createRealtimeSingleFrameSCSToken(data)
      //   .then(response => {
      //     const data = response.data.token;
      //
      //     resolve(data); // resolve
      //   })
      //   .catch(error => {
      //     reject(error);
      //   });
      axios.post(`https://videocallserver.kns-cloud.com/openvidu/api/sessions/${data}/connection`, {},  {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Basic T1BFTlZJRFVBUFA6MTIzNDU2"
        },
      })
        .then(response=>{
          resolve(response.data.token)
        })
        .catch(error=>{
          reject(error)
        })
    });
  },
  getDashboardLayout({commit, state}, siteId) {
    return new Promise((resolve, reject) => {
      getDashboardLayout(siteId)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getSCData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getSCData(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLRData({commit, state}, data) {
    return new Promise((resolve, reject) => {
      getLRData(data)
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getSafieDevice({commit, state}) {
    return new Promise((resolve, reject) => {
      getSafieListDevice()
        .then(response => {
          const data = response.data;

          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createUserShare({commit, state}, data) {
    return new Promise((resolve, reject) => {
      createUserShare(data)
        .then(response => {
          const data = response.data;
          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateUserShare({commit, state}, data) {
    return new Promise((resolve, reject) => {
      updateUserShare(data)
        .then(response => {
          const data = response.data;
          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  verifyTokenUserShare({commit, state}, data) {
    return new Promise((resolve, reject) => {
      verifyTokenUserShare(data)
        .then(response => {
          const data = response.data;
          if (data.code === CONSTANTS.SUCCESS) {
              setToken(data.token); // set token after login
          }
          resolve(data); // resolve
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};


const state = () => ({});
const getters = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

