<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <button v-if="!isGuestSite" @click="onExportToFile()" type="button" class="btn bg-white p-0" v-bind="attrs"
                    v-on="on">
                    <svg version="1.1" width="30" height="31" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 109.33 122.88"
                        style="enable-background:new 0 0 109.33 122.88" xml:space="preserve">
                        <g>
                            <path class="st0"
                                d="M82.28,105.33h-10.8V90.47H56.14v14.86H44.99l18.64,17.54L82.28,105.33L82.28,105.33z M102.4,33.95H83.61 c-1.98,0-4.73-1.06-6.03-2.36c-1.3-1.3-2.09-3.65-2.09-5.61V6.95l0,0H25.83c-0.16,0-0.29,0.08-0.37,0.16 c-0.14,0.09-0.18,0.21-0.18,0.38v87.73c0,0.12,0.09,0.29,0.16,0.37c0.09,0.13,0.26,0.17,0.38,0.17h12.96 c-1.03,2.16-1.79,4.49-2.23,6.91H23.7c-1.46,0-2.76-0.58-3.73-1.55c-0.96-0.96-1.54-2.26-1.54-3.73V5.32 c0-1.46,0.59-2.76,1.54-3.73c0.97-0.97,2.31-1.55,3.73-1.55h55.31C79.13,0,79.25,0,79.38,0c0.58,0,1.18,0.25,1.59,0.62h0.08 c0.08,0.05,0.13,0.09,0.21,0.16l27.27,27.61c0.46,0.46,0.8,1.09,0.8,1.8c0,0.21-0.04,0.37-0.08,0.6c0,25.22,0,41.39,0,66.61 c0,1.46-0.59,2.77-1.55,3.73c-0.97,0.96-2.27,1.55-3.73,1.55H90c-0.44-2.43-1.19-4.75-2.23-6.91h14.07c0.16,0,0.16-0.09,0.25-0.17 c0.13-0.08,0.3-0.26,0.3-0.37C102.39,71.78,102.4,57.39,102.4,33.95L102.4,33.95z M81.73,25.43V8.12l19.49,19.74H84.16 c-0.67,0-1.26-0.29-1.72-0.71C82.01,26.74,81.73,26.1,81.73,25.43L81.73,25.43L81.73,25.43z" />
                            <polygon class="st1" points="0,38.51 88.91,38.51 88.91,81.22 0,81.22 0,38.51" />
                            <path class="st2"
                                d="M10.92,48.47h7.81l4.06,7.02l3.94-7.02h7.71l-7.12,11.04l7.79,11.75h-7.97l-4.49-7.32l-4.53,7.32h-7.9 l7.9-11.88L10.92,48.47L10.92,48.47z M37.59,48.47h7.07v17.19h11.07v5.6H37.59V48.47L37.59,48.47z M57.93,63.73l6.72-0.42 c0.14,1.08,0.44,1.91,0.89,2.47c0.73,0.92,1.77,1.38,3.12,1.38c1.01,0,1.79-0.23,2.33-0.71c0.55-0.47,0.82-1.02,0.82-1.64 c0-0.59-0.26-1.12-0.77-1.59c-0.52-0.47-1.72-0.9-3.62-1.32c-3.1-0.69-5.31-1.61-6.63-2.76c-1.33-1.15-2-2.61-2-4.39 c0-1.17,0.34-2.27,1.02-3.31c0.68-1.05,1.71-1.86,3.08-2.46c1.37-0.59,3.25-0.89,5.63-0.89c2.93,0,5.16,0.54,6.69,1.63 c1.54,1.08,2.45,2.81,2.74,5.17l-6.65,0.39c-0.18-1.03-0.55-1.79-1.12-2.26c-0.57-0.47-1.35-0.71-2.35-0.71 c-0.82,0-1.45,0.17-1.86,0.52c-0.42,0.34-0.63,0.77-0.63,1.27c0,0.36,0.17,0.68,0.51,0.98c0.33,0.3,1.12,0.58,2.36,0.84 c3.09,0.66,5.3,1.34,6.64,2.02c1.34,0.68,2.31,1.52,2.92,2.53c0.61,1,0.91,2.13,0.91,3.37c0,1.46-0.4,2.81-1.21,4.04 c-0.82,1.23-1.95,2.17-3.41,2.81c-1.46,0.64-3.29,0.96-5.51,0.96c-3.89,0-6.59-0.75-8.09-2.24C58.97,67.92,58.12,66.02,57.93,63.73 L57.93,63.73z" />
                        </g>
                    </svg>
                </button>
            </template>
            <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ $t("tooltip.report") }}</span>
        </v-tooltip>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
    </span>
</template>
<script>
import { mapActions } from 'vuex';
import { getCookieSiteUser, getRememberKCloudID, getRoleID } from "@/utils/auth";
import {
    getToken,
} from "@/utils/auth";
import axios from "axios";
export default {
    props: ['frameId', 'frameName'],
    data() {
        return {
            loading: false,
            showModalSetting: false,
            SiteNameInput: null,
            userNameLogin: null
        }
    },
    computed: {
        isGuestSite() {
            return getRoleID() == 4;
        }
    },
    created() {
        this.InitValueDefault();
    },
    methods: {
        ...mapActions("report", {
            exportDataToFile: "exportDataToFile",
            exportDataToFilePDF: "exportDataToFilePDF"
        }),
        ...mapActions("sites", {
            getCbbSites: "getCbbSites"
        }),
        ...mapActions("users", {
            getKnsUserDetail: "getKnsUserDetail",
        }),
        pad2(n) { return n < 10 ? '0' + n : n },
        async onExportToFile() {
            let siteID = getCookieSiteUser();
            // site id is not exist
            if (!siteID) {
                return;
            }
            if (this.deviceCode === null) {
                return;
            }
            let exportType = "excel";
            let request = {
                "FrameId": this.frameId,
                "ExportType": exportType,
                "SiteId": siteID,
                "orderType": "desc",
                "deviceCode": 0,
                "locale": this.$i18n.locale,
                "reportTitle": null,
                "projectAdministrator": this.userNameLogin,
                "startDate": null,
                "endDate": null,
                'interval': 0,
                "SiteIdInput": siteID,
                "SiteNameInput": this.SiteNameInput,
                "stationSelectedLst": [],
                "displayIntervalName": null,
                "tsConfigCheck": false,
                "MValue": 0,
                "ThresholdValue": 0,
                "thresholdLegendCheck": 2,
                "FrameType" : "0",
                "IsQuickExport": true
            }
            await this.downloadFileExcel(request);
        },
        downloadFileExcel(request) {
            this.loading = true;
            // const fileName = "report_" + request.SiteId + ".xlsx"
            // const token = getToken();
            // const CS_DS = "CS_DS";

          this.exportDataToFile(request).then((response) => {
            const url = URL.createObjectURL(new Blob([response], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            }))
            const link = document.createElement('a')
            link.href = url
            let date = new Date();
            const fileName = this.frameName + "_" +date.getFullYear().toString() + this.pad2(date.getMonth() + 1) + this.pad2( date.getDate()) + this.pad2( date.getHours() ) + this.pad2( date.getMinutes() ) + this.pad2( date.getSeconds() )+ ".xlsx"
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          }).catch((error) => {
            console.log('[exportDataToFile]', error);
          }).finally(() => {
            this.loading = false;
          });
        },
        InitValueDefault(numer) {
            let siteID = getCookieSiteUser();
            if (!siteID) {
                return;
            }
            this.getCbbSites(0)
            .then(response => {
            if (response.code === 0) {
                let allSiteData = [];
                let tempData = response.data ?? [];
                for (let i = 0; i < tempData.length; i++) {
                allSiteData.push({
                    value: tempData[i].id,
                    text: tempData[i].siteName
                })
                }
                this.SiteNameInput = allSiteData.find((ele) => ele.value == siteID).text;
                this.getKnsUserDetail(getRememberKCloudID()).then((response) => {
                if (response.code === 0) {
                    let selectedUser = response.data;
                    this.userNameLogin = selectedUser.userName;
                } else {
                    this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                }
                }).catch((error) => {
                this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning');
                })
            } else {
                this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
            }
            })
        },
    },

}
</script>
<style scoped>
</style>
