<template>
  <div class="wrappers bg-gray-light">
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
            </div>
          </div>
        </div>
        <div class="content">
          <div class="error-page">
            <div class="no-content">
              本サービスは公開期間外です
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpiredLinkPage'
};
</script>

<style scoped>
.error-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2rem;
}
</style>
