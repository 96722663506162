<template>
  <div ref="StackedLineMain" class="card card-iphone" :style="style">
    <div class="card-header bg-gray-light border-0 pb-1" ref="headerChart">
      <h3 class="card-title font-weight-bolder">
        <i class="fas fa-th mr-1"></i>
        <v-tooltip top>
          <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ title }}</span>
          <template v-slot:activator="{ on }">
            <span style="font-weight: 600;" v-if="title !== undefined && title !== null && title.length > 50">...</span>
            <span style="font-weight: 600;"
                  v-on="on">{{ (title !== undefined && title !== null) ? title.slice(0, 50) : "" }}</span>
          </template>
        </v-tooltip>
      </h3>
      <div v-if="!isFrameSetting" class="card-tools">
        <button v-if="!isGuestSite && (link !== undefined || type === 'SCS') && type !== 'MSC' && isDashboard" v-on:click="openShareLinkModal('SCS')" type="button"
                class="btn bg-btn-card-tool btn-sm mr-2">
          <v-icon dark>mdi-share-variant-outline</v-icon>
        </button>
        <button v-if="!isGuestSite && (link !== undefined || type === 'SCS') && type !== 'MSC' && isDashboard" v-on:click="openInNewWindow('SCS')" type="button"
                class="btn bg-btn-card-tool btn-sm">
          <v-icon dark>mdi-desktop-mac</v-icon>
        </button>
        <button v-if="isShowEdit"
                type="button"
                class="btn btn-sm" @click="clickClose">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body" style="position: relative; padding: 0;">
      <video ref="screenVideoSharing" width="100%" height="100%" autoplay muted></video>
<!--      <div v-if="!playing && !loading" @click.prevent="playVideo" class="custom-overlay">-->
<!--        <font-awesome-icon :icon="['fas', 'play']" size="2xl"/>-->
<!--      </div>-->
      <div v-if="isNoScreen && !loading" class="no-content">
        {{ $t("notification.noScreen") }}
      </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
    <ShareLinkModal @close="showModalSetting = false" :deviceTSList="[]" :frameId="frameId" :session="link"/>
  </div>
</template>

<script>
import {OpenVidu} from "openvidu-browser";
import {mapActions} from "vuex";
import {isEmpty} from "lodash";
import $ from 'jquery';
import {EventBus} from "@/utils/eventBus";
import ShareLinkModal from "@/modals/Share/ShareLinkModal";
import { getRoleID } from "@/utils/auth";

export default {
  name: "SCS",
  components: { ShareLinkModal },
  props: {
    height: Number,
    width: Number,
    type: String,
    title: String,
    isShowEdit: Boolean,
    link : String,
    frameId: Number,
    isDashboard: Boolean,
    isFrameSetting: Boolean
  },
  data() {
    return {
      ERROR_CODE : "",
      heightReal: null,
      loading: false,
      // OpenVidu objects
      OV: undefined,
      session: undefined,
      subscribers: [],
      playing : true,
      // Join form
      myUserName: "K-Cloud user",
      latestStreamTime : 0
    }
  },
  computed: {
    style() {
      // eslint-disable-next-line
      this.heightReal = this.height;
      return "height: " + this.heightReal + "px";
    },
    mainStream() {
      if (!isEmpty(this.subscribers)) {
        return this.subscribers[this.subscribers.length - 1]
      }
      return undefined
    },
    isNoScreen(){
      return isEmpty(this.subscribers)
    },
    linkUrl() {
      if (this.link) {
        return this.link;
      } else {
        return "";
      }
    },
    isGuestSite() {
        return getRoleID() == 4;
    }
  },
  created() {
    this.link && this.joinSession()
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/dashboard/${currentLang}.js`)
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/dashboard/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })
    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  methods: {
    ...mapActions("dashboard", {
      getRealtimeSingleFrameSCSData: "getRealtimeSingleFrameSCSData",
      createRealtimeSingleFrameSCSToken: "createRealtimeSingleFrameSCSToken",
      createRealtimeSingleFrameSCSData : "createRealtimeSingleFrameSCSData"
    }),
    joinSession() {
      this.loading = true
      // --- 1) Get an OpenVidu object ---
      this.OV = new OpenVidu();

      // --- 2) Init a session ---
      this.session = this.OV.initSession();

      // --- 3) Specify the actions when events take place in the session ---

      // On every new Stream received...
      this.session.on("streamCreated", ({stream}) => {
        if(stream.creationTime > this.latestStreamTime) {
          this.latestStreamTime = stream.creationTime
          const subscriber = this.session.subscribe(stream);
          this.subscribers = [subscriber]
        }
      });

      // On every Stream destroyed...
      this.session.on("streamDestroyed", ({stream}) => {
        const index = this.subscribers.indexOf(stream.streamManager, 0);
        if (index >= 0) {
          this.subscribers.splice(index, 1);
        }
      });

      this.session.on("reconnected", () => {
        console.log("Reconnected")
      })

      // On every asynchronous exception...
      this.session.on("exception", (event) => {
        if (event.name === 'ICE_CONNECTION_FAILED') {
          const stream = event.origin;
          console.warn('Stream ' + stream.streamId + ' broke!');
          console.warn('Reconnection process automatically started');
        }
        if (event.name === 'ICE_CONNECTION_DISCONNECTED') {
          const stream = event.origin;
          console.warn('Stream ' + stream.streamId + ' disconnected!');
          console.warn('Giving it some time to be restored. If not possible, reconnection process will start');
        }
      });

      // --- 4) Connect to the session with a valid user token ---
      const session = "share_screen_" + this.link.replaceAll(".", "_");
      this.getToken(session)
        .then((token) => {
          this.session.connect(token, {clientData: this.myUserName}).then(() => {
            this.loading = false
          })
            .catch((error) => {
              this.loading = false
              console.log("There was an error connecting to the session:", error.code, error.message);
            });
        })
        .catch(() => {
          this.createTokenCreateSession(session)
            .then((response) => {
              this.session.connect(response, {clientData: this.myUserName}).then(() => {
                this.loading = false
              })
                .catch((error) => {
                  this.loading = false
                  console.log("There was an error connecting to the session:", error.code, error.message);
                });
            })
            .catch((e) => {
              this.loading = false
              console.log(e)
            })
        })
    },
    leaveSession() {
      // --- 7) Leave the session by calling 'disconnect' method over the Session object ---
      if (this.session) this.session.disconnect();

      // Empty all properties...
      this.session = undefined;
      this.subscribers = [];
      this.OV = undefined;

      // Remove beforeunload listener
      window.removeEventListener("beforeunload", this.leaveSession);
    },
    async getToken(mySessionId) {
      const sessionId = await this.getSession(mySessionId);
      return await this.createToken(sessionId.sessionId);
    },

    async createTokenCreateSession(mySessionId) {
      const sessionId = await this.createRealtimeSingleFrameSCSData(mySessionId);
      return await this.createToken(sessionId.sessionId);
    },

    async getSession(sessionId) {
      return await this.getRealtimeSingleFrameSCSData(sessionId);
    },

    async createToken(sessionId) {
      return await this.createRealtimeSingleFrameSCSToken(sessionId);
    },
    initVideo() {
      if (this.mainStream) {
        this.playing = false
        this.mainStream.addVideoElement(this.$refs.screenVideoSharing)
        return
      }
      this.playing = true
    },
    clickClose() {
      this.$emit(`closeFrame`, this.frameId);
    },
    playVideo() {
      this.$refs.screenVideoSharing.play()
      this.playing = true
    },
    openInNewWindow(cam) {
      let routeData = []
      let newWindow = null
      if (cam === 'SCS' && this.mainStream) {
        routeData = this.$router.resolve({
          path: '/screenShare',
          query: { session: this.linkUrl },
          append: true,
        });
        newWindow = window.open(routeData.href, this.title,
          'width=1280px,height=720px,toolbar=0,menubar=0,location=0');
        if (window.focus) {
          newWindow.focus()
        }
      } else {
        this.commonErrorVue(this.$t("messages.noScreenShare"), "warning")
      }
    },
    openShareLinkModal(cam) {
      this.editedItem = {}
      this.modelCreate = true
      EventBus.$emit('handleOpenShareLinkModal' + this.frameId, {
        title: this.$t('userShare.screenShare'),
        shareLinkType: 1,
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        editMode: false
      });
      this.showModalSetting = true;
      $('#main-header').css("pointer-events", "none");
    },
  },
  destroyed() {
    this.leaveSession()
  },
  watch: {
    "mainStream"() {
      this.initVideo()
    },
    '$i18n.locale': async function (currentLang) {

      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/dashboard/${currentLang}.js`)
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/dashboard/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })
      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    },
  }
}
</script>

<style scoped lang="scss">
.screen-sharing-main {
  width: 100%;
  height: 100%;
  background: #2c2c2c;
}
.custom-overlay{
  background : white;
  position: absolute;
  width : 100%;
  height : 100%;
  top : 0;
  left : 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.no-content{
  padding: 20px;
  border-radius: 7px;
  position: absolute;
  background: rgb(0 0 0 / 78%);
  top: 50%;
  left : 50%;
  transform: translate(-50%, -50%);
  color: white;
  white-space: nowrap;
}
.bg-btn-card-tool{
  background-color: #3d76ad !important;
}


</style>
