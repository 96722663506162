import Cookies from "js-cookie";

const TokenKey = "KEINET-Token";
const KCloud_MENU_TYPE = "KEINET-Menu-Access";
const I18_LANG = 'KEINET-I18-Lang'
const KClod_ID = 'KClod_ID'
const KCloud_PASS = 'KCloud_PASS'
const KCloud_REMEMBER = 'KCloud_REMEMBER'
const KCloud_USER_TYPE = 'KCloud_USER_TYPE'
const KCloud_USER_SITE = 'KCloud_USER_SITE'
const KCloud_MENU_ACCESS = 'KCloud_MENU'
const KCloud_CURRENT_PAGE = 'KCloud_CURRENT_PAGE'
const Safie_TOKEN = 'SAFIE_TOKEN'
const KCloud_RoleID = 'KCloud_RoleID'

export function getSafieToken() {
  return Cookies.get(Safie_TOKEN);
}

export function setSafieToken(token) {
  return Cookies.set(Safie_TOKEN, token, {expires: 1, domain: document.domain.split('.').reverse().splice(0,2).reverse().join('.')});
}

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {expires: 1});
}

export function removeToken() {
  Cookies.remove(TokenKey);
}

export function setMenuAccesses(userType) {
  return Cookies.set(KCloud_MENU_TYPE, userType, {expires: 1});
}

export function getMenuAccesses() {
  return Cookies.get(KCloud_MENU_TYPE);
}

export function removeMenuAccesses() {
  Cookies.remove(KCloud_MENU_TYPE);
}

export function cookiesSetI18Lang (lang) {
  Cookies.set(I18_LANG, lang, {expires: 1})
}

export function cookiesGetI18Lang () {
  return Cookies.get(I18_LANG)
}

export function cookiesRemoveI18Lang () {
  return Cookies.remove(I18_LANG)
}

export function setRememberKCloudID(userId) {
  return Cookies.set(KClod_ID, userId, {expires: 1});
}

export function getRememberKCloudID() {
  return Cookies.get(KClod_ID);
}

export function setRememberKCloudPass(password) {
  return Cookies.set(KCloud_PASS, password, {expires: 1});
}

export function getRememberKCloudPass() {
  return Cookies.get(KCloud_PASS);
}

export function setRememberMe(type, flag) {
  return Cookies.set(KCloud_REMEMBER, type, (flag ? {expires: 1} : {}));
}

export function getRememberMe() {
  return Cookies.get(KCloud_REMEMBER);
}

export function setCookieUserType(userType){
  return Cookies.set(KCloud_USER_TYPE, userType, {expires: 1});
}

export function removeCookieUserType(){
  return Cookies.remove(KCloud_USER_TYPE);
}

export function getCookieUserType(){
  return Cookies.get(KCloud_USER_TYPE);
}
export function setCookieSiteUser(SiteId){
  return Cookies.set(KCloud_USER_SITE, SiteId, {expires: 1});
}

export function removeCookieSiteUser(){
  return Cookies.remove(KCloud_USER_SITE);
}

export function getCookieSiteUser(){
  return Cookies.get(KCloud_USER_SITE);
}

export function setCookiePrivileges(menu){
  return localStorage.setItem(KCloud_MENU_ACCESS, JSON.stringify(menu));
}

export function getCookiePrivileges(){
  return localStorage.getItem(KCloud_MENU_ACCESS);
}

export function removeCookiePrivileges(){
  return localStorage.removeItem(KCloud_MENU_ACCESS);
}

export function setCurrentPageCode(pageCode){
  return Cookies.set(KCloud_CURRENT_PAGE, pageCode);
}

export function getCurrentPageCode(){
  return Cookies.get(KCloud_CURRENT_PAGE);
}

export function removeCurrentPageCode(){
  return Cookies.remove(KCloud_CURRENT_PAGE);
}

export function setDisplaySetting(deviceCode, displaySetting){
  return Cookies.set(deviceCode, displaySetting);
}

export function getDisplaySetting(deviceCode){
  return Cookies.get(deviceCode);
}

export function setRoleID(roleID){
  return Cookies.set(KCloud_RoleID, roleID);
}

export function getRoleID(){
  return Cookies.get(KCloud_RoleID);
}

export function removeRoleID(){
  return Cookies.remove(KCloud_RoleID);
}
