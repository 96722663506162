<template>
  <div class="text-center m-2">
    <v-menu offset-y rounded>
      <template v-slot:activator="{ on, attrs }">
        <font-awesome-icon icon="fa-solid fa-circle-user" class="ml-2 mr-4" style="color : white;font-size: 30px"
                           v-bind="attrs" v-on="on"/>
      </template>
      <v-list
        v-for="(item, index) in menuComputed"
        :key="index"
        link
      >
        <v-list-item v-on:click="handleSelectItem(item)">
          <v-list-item-title>
            <v-icon :color="item.color" style="width: 32px; height: 24px">{{ item.flag }}</v-icon>
            {{ $t(item.label) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <UserProfileModal :dialog.sync="systemUserFlag" :detail-flag="checkDetail"></UserProfileModal>
    <UserManagerProfileModal v-if="userType === 4" :dialog.sync="userManagementFlag" :detail-flag="checkDetail"></UserManagerProfileModal>
    <HelpModal></HelpModal>
    <ShareLinkModal @close="showModalSetting = false" :deviceTSList="[]" :frameId="0" :session="null"/>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import {
  cookiesRemoveI18Lang,
  getCookiePrivileges, getCookieUserType,
  getRememberKCloudID,
  getToken, removeCookiePrivileges,
  removeCookieSiteUser,
  removeCookieUserType, removeCurrentPageCode,
  removeMenuAccesses,
  removeToken,
  getMenuAccesses
} from '@/utils/auth';
import UserProfileModal from "@/modals/UserProfileModal/UserProfileModal";
import UserManagerProfileModal from "@/modals/UserProfileModal/UserManagerProfileModal";
import ShareLinkModal from "@/modals/Share/ShareLinkModal";
import HelpModal from "@/modals/Help/HelpModal";
const i18nConfig = require('../../i18n.lang')
import {EventBus} from "@/utils/eventBus";
import $ from 'jquery';
export default {
  components: {UserManagerProfileModal, UserProfileModal, HelpModal, ShareLinkModal},
  name: "UserDropdown",
  data: () => ({
    locale: 'jp',
    systemUserFlag: false,
    siteManagerFlag: false,
    userManagementFlag: false,
    userType: null,
    checkDetail: null,
    visible: false
  }),
  created() {
    this.userType = parseInt(getCookieUserType());
  },
  computed: {
    getCurrentLocale() {
      return this.$i18n.locale
    },
    getAllLocales() {
      return i18nConfig.locales
    },
    menuComputed() {
      if (this.$route.path.startsWith("/dashboard/temporary_token")) {
        return [
          {
            key: "question",
            flag: "mdi-help",
            label: "labels.help",
            color: "blue-grey darken-2"
          }
        ]
      }
      if (this.userType === 1 || this.userType === 2) {  
        if (getMenuAccesses() == 2) {
          return [
            {
              key: "back",
              flag: "mdi-arrow-left-circle",
              label: "labels.backKCloudManagerment",
              color: "blue darken-2"
            },
            {
              key: "user",
              flag: "mdi-account",
              label: "labels.userProfile",
              color: "blue darken-2"
            },
            {
              key: "question",
              flag: "mdi-help",
              label: "labels.help",
              color: "blue-grey darken-2"
            },
            {
              key: "logout",
              flag: "mdi-logout",
              label: "dashboard.logout",
              color: "orange darken-2"
            }
          ]
        } else {
          return [
            {
              key: "back",
              flag: "mdi-arrow-left-circle",
              label: "labels.backKCloudManagerment",
              color: "blue darken-2"
            },
            {
              key: "user",
              flag: "mdi-account",
              label: "labels.userProfile",
              color: "blue darken-2"
            },
            {
              key: "question",
              flag: "mdi-help",
              label: "labels.help",
              color: "blue-grey darken-2"
            },
            {
              key: "share",
              flag: "mdi-share-variant-outline",
              label: "userShare.share",
              color: "blue darken-2"
            },
            {
              key: "logout",
              flag: "mdi-logout",
              label: "dashboard.logout",
              color: "orange darken-2"
            }
          ]
        }

      } else {
        return [
          {
            key: "user",
            flag: "mdi-account",
            label: "labels.userProfile",
            color: "blue darken-2"
          },
          {
            key: "question",
            flag: "mdi-help",
            label: "labels.help",
            color: "blue-grey darken-2"
          },
          {
            key: "logout",
            flag: "mdi-logout",
            label: "dashboard.logout",
            color: "orange darken-2"
          }
        ]
      }
    }
  },
  async beforeMount() {
    // ========================================================== start import ERROR_CODE by language
    // ==============================================================================================
    const currentLang = this.$i18n.locale
    await import(`@/utils/errors/auth/${currentLang}.js` )
      .then((DATA) => {
        this.ERROR_CODE = DATA.ERROR_CODE
      })
      .catch(async () => {
        await import(`@/utils/errors/auth/${currentLang}.js`)
          .then((DATA) => {
            this.ERROR_CODE = DATA.ERROR_CODE
          })
      })

    // ==============================================================================================
    // =========================================================== stop import ERROR_CODE by language
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout"
    }),
    handleLogout() {
      this.commonConfirmVue(this.$t('confirm.msgLogout'), () => {
        const userId = getRememberKCloudID();
        const token = getToken();
        this.logout({userId: userId, token: token})
        .then(response => {
          removeToken();
          removeMenuAccesses();
          removeCookieUserType();
          removeCookieSiteUser();
          removeCurrentPageCode();
          removeCookiePrivileges();
          cookiesRemoveI18Lang();
          this.$router.push("/login");
          if (response.code === 0) {
            this.commonNotifyVue(this.ERROR_CODE['LOGIN_SUCCESS'], 'info');
          }
        })
        .catch(err => {
          console.log("[handleLogout] err", err);
        })
        .finally(final => {
        });
      });
    },
    handleSelectItem(item) {
      if (item.key === 'logout') {
        this.handleLogout();
      } else if (item.key === 'user') {
        if (this.userType === 1 || this.userType === 2) {
          this.systemUserFlag = true;
          this.checkDetail = true;
        } else if (this.userType === 3) {
          this.systemUserFlag = true;
          this.checkDetail = true;
        } else {
          this.userManagementFlag = true;
          this.checkDetail = true;
        }
      } else if (item.key === 'question') {
        EventBus.$emit('handleOpenHelpModal', {
          flag: true,
          title: this.$t('labels.help')
        })
      } else if (item.key === 'back') {
          // this.$router.go("/admin");
          // this.$router.replace({ path: '/admin' });
          removeCookieSiteUser();
          this.$router.push({ path: '/admin' });
          this.$router.go(0)
      } else if (item.key === 'share') {
        this.openShareLinkModal();
      }
    },
    openShareLinkModal() {
      this.editedItem = {}
      EventBus.$emit('handleOpenShareLinkModal0', {
        title: this.$t('userShare.dashboardShare'),
        shareLinkType: 2,
        editedItem: this.editedItem,
        flag: true,
        checkDetail: false,
        editMode: false
      });
      this.showModalSetting = true;
      $('#main-header').css("pointer-events", "none");
    },
  },
  watch: {
    '$i18n.locale': async function (currentLang) {
      // ========================================================== start import ERROR_CODE by language
      // ==============================================================================================
      await import(`@/utils/errors/auth/${currentLang}.js` )
        .then((DATA) => {
          this.ERROR_CODE = DATA.ERROR_CODE
        })
        .catch(async () => {
          await import(`@/utils/errors/auth/${currentLang}.js`)
            .then((DATA) => {
              this.ERROR_CODE = DATA.ERROR_CODE
            })
        })

      // ==============================================================================================
      // =========================================================== stop import ERROR_CODE by language
    }
  }
};
</script>

<style>

.dropdown-item {
  cursor: pointer;
}

.navbar-nav > .user-menu > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  width: auto;
}
</style>
