<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <button v-if="!isGuestSite" @click="onExportToFile()" type="button" class="btn bg-white mr-2 chartac" v-bind="attrs"
                    v-on="on" style="width: 35px;height: 35px;">
                    <i class="fas fa-times" style="visibility: hidden"></i>
                </button>
            </template>
            <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ $t("tooltip.graph") }}</span>
        </v-tooltip>

        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
        </v-overlay>
        <v-dialog content-class="mt-5 v-dialog--custom" v-model="showDialogPreview" :max-width="2100" :width="2100"
            persistent>
            <v-card>
                <v-card-title class="bg-gray-light">
                    <span class="text-h5 label-black">{{ $t("exportData.graphPreview") }}</span>
                    <v-btn @click="showDialogPreview = false" class="ml-auto" icon>
                        <v-icon id="close-button">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div style="color:black;font-weight: bold; font-size: 16px" ref="titlePdf" class="sheet padding-10mm">
                        <div>{{titlePdfPreview}}</div>
                        <div>{{labelTimePreview}}</div>
                    </div>
                    <div class="exportGraph-previewChart">
                        <div style="width:1800px; height: 400px;" ref="tagMain" class="card-body">
                        <v-chart ref="chartPreview" :option="{}" :initOptions="initOpts" @finished="onFinishedChart"
                            class="chartPreview" :autoresize="true" />
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </span>

</template>
<script>
import { mapActions } from 'vuex';
import {
    getToken,
} from "@/utils/auth";
import axios from "axios";
import jsPDF from 'jspdf';
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { UniversalTransition } from "echarts/features";
import { ResponseCode } from "@/constants/constants"
import { langEN } from "@/languages/echarts/langEN.js";
import { langJA } from "@/languages/echarts/langJA.js";
import { getCookieSiteUser, getRememberKCloudID, getRoleID } from "@/utils/auth";
import html2canvas from "html2canvas";
use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
]);
export default {
    props: ['frameId'],
    components: {
        // VChart
    },
    data() {
        return {
            loading: false,
            showModalSetting: false,
            showDialogPreview: false,
            initOpts: {
                locale: 'JA'
            },
            isFinishOneChart: false,
            splitNumber: 5,
            SiteNameInput: null,
            userNameLogin: null,
            titlePdfPreview: null,
            labelTimePreview: null,
            displayScaleMin: -10,
            displayScaleMax: 10,
            auxiliaryLine: 5
        }
    },
    computed: {
        isGuestSite() {
            return getRoleID() == 4;
        }
    },
    created() {
        this.InitValueDefault();
    },
    methods: {
        ...mapActions("report", {
            exportDataToFile: "exportDataToFile",
            exportDataToFilePDF: "exportDataToFilePDF"
        }),
        ...mapActions("users", {
            getKnsUserDetail: "getKnsUserDetail",
        }),
        ...mapActions("sites", {
            getCbbSites: "getCbbSites"
        }),
        pad2(n) { return n < 10 ? '0' + n : n },
        async onExportToFile() {
            let siteID = getCookieSiteUser();
            // site id is not exist
            if (!siteID) {
                return;
            }
            if (this.deviceCode === null) {
                return;
            }
            let exportType = "graph";
            let request = {
                "FrameId": this.frameId,
                "ExportType": exportType,
                "SiteId": siteID,
                "orderType": "desc",
                "deviceCode": 0,
                "locale": this.$i18n.locale,
                "reportTitle": null,
                "projectAdministrator": this.userNameLogin,
                "startDate": null,
                "endDate": null,
                'interval': 0,
                "SiteIdInput": siteID,
                "SiteNameInput": this.SiteNameInput,
                "stationSelectedLst": [],
                "displayIntervalName": null,
                "tsConfigCheck": false,
                "MValue": 0,
                "ThresholdValue": 0,
                "thresholdLegendCheck": 2,
                "SplitNumber": this.splitNumber,
                "FrameType" : "0",
                "IsQuickExport": true
            }
            this.loading = true;
            this.exportDataToFilePDF(request)
                .then(response => {
                    console.log('[exportDataToFile]', response);
                    if (response.code === ResponseCode.SUCCESS) {
                        this.downloadFilePdf(request, response);
                    }
                    else {
                        this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                        this.loading = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                })
                .finally(final => {
                    
                });
        },
        async downloadFilePdf(request, response) {
            this.loading = true;
            var { titlePdf, labelTime } = response;
            var splitNumber  = response.splitNumber != null ? response.splitNumber : 5;
            var ngCheck  = response.ngCheck != null ? response.ngCheck : 2;
            var dataChart = response.data;
            this.titlePdfPreview = titlePdf;
            this.labelTimePreview = labelTime;
            this.showDialogPreview = true;
            this.initOpts.locale = this.$i18n.locale === 'en' ? langEN : langJA;
            this.auxiliaryLine = response.auxiliaryLine != null ? response.auxiliaryLine : 5;
            this.displayScaleMin = response.minimumValueOfAxis != null ? response.minimumValueOfAxis : -10;
            this.displayScaleMax = response.maximumValueOfAxis != null ? response.maximumValueOfAxis : 10;
            await this.sleep(1 * 500);
            let chart = this.$refs.chartPreview;
            let titlePdfE = this.$refs.titlePdf;
            // chart.setInit(this.initOpts);
            try {
                const doc = new jsPDF({
                    unit: "px",
                    orientation: "landscape",
                    hotfixes: ["px_scaling"]
                });
                const pageMargin = 10;
                const scale = 1/*window.devicePixelRatio;*/
                const marginBottom = 25;
                doc.setFontSize(12);
                
                const canvas = await html2canvas(titlePdfE, { scale: 1 });
                const dataUrl = canvas.toDataURL();
                let imgTitle = this.loadImage(dataUrl);
                doc.addImage(dataUrl, "JPG", pageMargin, 25, imgTitle.width / (scale * 1.5), imgTitle.height / (scale * 1.75));
                // if(response.frameType === "0"){
                if (request.deviceCode !== "200" && request.deviceCode !== "300") {
                var startY = 75;
                var groupByStationOther = this.groupBy(dataChart, 'siteStationId');
                    for (const station in groupByStationOther) {

                        var dataChartOther = groupByStationOther[station]
                        var legendListOther = [];
                        var seriesListOther = [];
                        for (let index = 0; index < dataChartOther.length; index++) {
                            if (dataChartOther[index].data === undefined || dataChartOther[index].data === null || dataChartOther[index].data.length === 0) {
                                continue;
                            }
                            let dataGraphOther = [];
                            let dataGraphNotNullOther = [];
                            var chartNameOther = this.makeLegendName(dataChartOther[index]);
                            for (const [key, value] of Object.entries(dataChartOther[index].data)) {
                                const dateNumber = this.convertStringToDateNumber(key);
                                dataGraphOther.push([dateNumber, value]);
                                if(value !== null){
                                    dataGraphNotNullOther.push(value);
                                }
                            }
                            var isNoDataOther = (dataGraphNotNullOther.length === 0);
                            var seriesObjOther = {
                                data: dataGraphOther,
                                type: "line",
                                name: chartNameOther,
                                showSymbol: false,
                                color: this.isDevice200or300(dataChartOther[index].deviceCode) ? this.getColor200ByDataCode(dataChartOther[index].dataCode) : '#0000ff',
                                connectNulls: !this.ngCheck
                            }
                            legendListOther.push(chartNameOther);
                            seriesListOther.push(seriesObjOther);
                            if (!this.isDevice200or300(dataChartOther[index].deviceCode) || (this.isDevice200or300(dataChartOther[index].deviceCode) && dataChartOther[index].isBreakPage)) {
                                var dataChartOtherW = [];
                                if (!this.isDevice200or300(dataChartOther[index].deviceCode)){
                                    dataChartOtherW.push(dataChartOther[index]);
                                } else {
                                    dataChartOtherW = dataChartOther;
                                }
                                for (let j = 0; j < dataChartOtherW.length; j++) {
                                    const warningLst = dataChartOtherW[j].warningData;
                                    warningLst.forEach(element => {
                                        var elementType = this.isDevice200or300(dataChartOther[index].deviceCode) ? element.type.split("_")[0] : element.type;
                                        legendListOther.push(elementType);
                                        var warning = {
                                            data: Object.keys(dataChartOtherW[j].data).map((key) => { return [this.convertStringToDateNumber(key), element.value] }),
                                            type: "line",
                                            name: elementType,
                                            showSymbol: false,
                                            symbolSize: 0,
                                            color: element.color,
                                        }
                                        seriesListOther.push(warning);
                                    });   
                                }
                                await chart.setOption({
                                    animation: false,
                                    // tooltip: {
                                    //     trigger: 'axis',
                                    //     position: function (pt) {
                                    //         return [pt[0], '10%'];
                                    //     }
                                    // },
                                    textStyle: {
                                        color: "black",
                                        fontSize: 16
                                    },
                                    backgroundColor: "#fff",
                                    legend: { data: legendListOther },
                                    xAxis: {
                                        type: 'time',
                                        boundaryGap: false,
                                        splitLine: {
                                            interval: 1,
                                            show: true,
                                            lineStyle: {
                                                type: "dotted",
                                                color: "black"
                                            }
                                        },
                                        axisTick: { show: false },
                                        axisLabel: {
                                            fontSize: 16,
                                            formatter: {
                                                year: '{yearStyle|{yyyy}/{MM}}',
                                                month: '{MMM}',
                                                day: '{MM}/{dd}',
                                                hour: '{HH}:{mm}',
                                                minute: '{HH}:{mm}',
                                                second: '{HH}:{mm}:{ss}',
                                                millisecond: '{hh}:{mm}:{ss} {SSS}',
                                                none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                                            },
                                            rich: {
                                                yearStyle: {
                                                    // Make yearly text more standing out
                                                    color: '#000',
                                                    fontWeight: 'bold'
                                                }
                                            }
                                        },
                                        axisLine: {
                                            lineStyle: {
                                                color: 'black',
                                                width: 2,
                                            },
                                            show: false
                                        },
                                        splitNumber: splitNumber
                                    },
                                    yAxis: {
                                        name: dataChartOther[index].unit,
                                        type: 'value',
                                        boundaryGap: [0, '100%'],
                                        nameTextStyle: {
                                            color: "black",
                                            fontSize: 16,
                                            fontWeight: "bold",
                                        },
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                type: "solid",
                                                color: ['#000000']
                                            }
                                        },
                                        axisLabel: {
                                            fontSize: 16,
                                            fontWeight: "bold"
                                        },
                                        interval: dataChartOther[index].isDeviceTs  && dataChartOther[index].frameDataId == 0 ? this.auxiliaryLine : undefined,
                                        min: isNoDataOther ? 0 : this.minChartTs(dataChartOther[index].isDeviceTs),
                                        max: dataChartOther[index].deviceCode == 100 && dataChartOther[index].dataMapping == "data5" ? 400 : (isNoDataOther ? 100 : this.maxChartTs(dataChartOther[index].isDeviceTs))
                                    },
                                    series: seriesListOther
                                });
                                await this.sleep(1 * 300);
                                let img = null;
                                if (this.isFinishOneChart) {
                                    img = await this.getChartImage(chart);
                                }
                                var w = 1760 / (scale * 1.5);
                                var h = this.isDevice200or300(dataChartOther[index].deviceCode) ? (360 / (scale * 1.25)) : (360 / (scale * 1.75));
                                doc.addImage(img.src, "JPG", 0, startY, w, h);
                                console.log("width ---- ", img.width);
                                console.log("height-----", img.height);
                                legendListOther = [];
                                seriesListOther = [];
                                chart.clear();
                                startY = startY + (360 / (scale * 1.75)) + marginBottom;
                                if ((startY > 605 || dataChartOther[index].isBreakPage) && !dataChartOther[index].isLastLine) {
                                    startY = 25;
                                    doc.addPage();
                                }
                            }

                        }

                    }
                    this.showDialogPreview = false;
            }
            else {
                var startY200 = 125
                var groupByStation = this.groupBy(dataChart, 'siteStationId');
                for (const station in groupByStation) {
                    var legendList = [];
                    var seriesList = [];
                    var dataChart200300 = groupByStation[station]
                    for (let index = 0; index < dataChart200300.length; index++) {
                        if (dataChart200300[index].data === undefined || dataChart200300[index].data === null || dataChart200300[index].data.length === 0) {
                            continue;
                        }
                        var chartName200 = this.makeLegendName(dataChart200300[index]);
                        let dataGraph200 = [];
                        for (const [key, value] of Object.entries(dataChart200300[index].data)) {
                            const dateNumber = this.convertStringToDateNumber(key);
                            dataGraph200.push([dateNumber, value]);
                        }
                        var seriesObj = {
                            data: dataGraph200,
                            type: "line",
                            name: chartName200,
                            showSymbol: false,
                            color: this.getColor200ByDataCode(dataChart200300[index].dataCode),
                            connectNulls: !(ngCheck === 1)
                        }
                        legendList.push(chartName200);
                        seriesList.push(seriesObj);
                    }
                    for (let index = 0; index < dataChart200300.length; index++) {
                            const warningLst = dataChart200300[index].warningData;
                            warningLst.forEach(element => {
                                legendList.push(element.type);
                                var warning = {
                                    data: Object.keys(dataChart200300[index].data).map((key) => { return [this.convertStringToDateNumber(key), element.value] }),
                                    type: "line",
                                    name: element.type,
                                    showSymbol: false,
                                    symbolSize: 0,
                                    color: element.color,
                                }
                                seriesList.push(warning);
                            });
                            
                    }
                    await chart.setOption({
                        animation: false,
                        // tooltip: {
                        //     trigger: 'axis',
                        //     position: function (pt) {
                        //         return [pt[0], '10%'];
                        //     }
                        // },
                        textStyle: {
                            color: "black",
                            fontSize: 16
                        },
                        backgroundColor: "#fff",
                        legend: {
                            data: legendList,
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: false,
                            splitLine: {
                                show: true
                            },
                            axisLabel: {
                                formatter: {
                                    year: '{yearStyle|{yyyy}/{MM}}',
                                    month: '{MMM}',
                                    day: '{MM}/{dd}',
                                    hour: '{HH}:{mm}',
                                    minute: '{HH}:{mm}',
                                    second: '{HH}:{mm}:{ss}',
                                    millisecond: '{hh}:{mm}:{ss} {SSS}',
                                    none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                                },
                                rich: {
                                    yearStyle: {
                                        // Make yearly text more standing out
                                        color: '#000',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            splitNumber: splitNumber
                        },
                        yAxis: {
                            name: dataChart200300[0].unit,
                            type: 'value',
                            boundaryGap: [0, '100%'],
                            nameTextStyle: {
                                color: "black",
                                fontSize: 16,
                                fontWeight: "bold",
                            }
                            ,
                            axisLabel: {
                                fontSize: 16,
                                // color: "red",
                                fontWeight: "bold",
                            }
                        },
                        series: seriesList
                    });
                    if (seriesList !== undefined && seriesList !== null && seriesList.length > 0) {
                        await this.sleep(1 * 300);
                        let img = null;
                        if (this.isFinishOneChart) {
                            img = await this.getChartImage(chart);
                        }
                        // doc.text(550, 100, dataChart200300[0].siteStationId);
                        doc.addImage(img.src, "JPG", 0, startY200, 1760 / (scale * 1.5), 360 / (scale * 1.25));
                        chart.clear();
                        startY200 = startY200 + (360 / (scale * 1.75)) + marginBottom;
                        doc.addPage();
                        if (startY200 > 655) {
                            startY200 = 25;
                            doc.addPage();
                        }
                    }
                    if (doc.internal.getNumberOfPages() === 0)
                        doc.addPage();
                }
                //delete last page
                var pageCount = doc.internal.getNumberOfPages();
                doc.deletePage(pageCount)
                this.showDialogPreview = false;
            }
                // }
                // else{
                //     startY200 = 125;
                //     legendList = [];
                //     seriesList = [];
                //     for (let index = 0; index < dataChart.length; index++) {
                //             if (dataChart[index].data === undefined || dataChart[index].data === null || dataChart[index].data.length === 0) {
                //                 continue;
                //             }
                //             let dataGraph = [];
                //             for (const [key, value] of Object.entries(dataChart[index].data)) {
                //                 const dateNumber = this.convertStringToDateNumber(key);
                //                 dataGraph.push([dateNumber, value]);
                //             }
                //             seriesObj = {
                //                 data: dataGraph,
                //                 type: "line",
                //                 name: dataChart[index]?.legendName,
                //                 showSymbol: false,
                //                 color: dataChart[index].color,
                //                 connectNulls: !(ngCheck === 1)
                //             }
                //             legendList.push(dataChart[index]?.legendName);
                //             seriesList.push(seriesObj);

                //             const warningLst = dataChart[index].warningData;
                //             warningLst.forEach(element => {
                //                 legendList.push(element.type);
                //                 var warning = {
                //                     data: [[this.convertStringToDateNumber(response.startDate),element.value],[this.convertStringToDateNumber(response.endDate),element.value]],
                //                     type: "line",
                //                     name: element.type,
                //                     showSymbol: false,
                //                     color: element.color,
                //                 }
                //                 seriesList.push(warning);
                //             });
                //     }
                //     await chart.setOption({
                //             animation: false,
                //             title: {
                //                 left: 'center',
                //                 text: response.deviceName
                //             },
                //             tooltip: {
                //                 trigger: 'axis',
                //                 position: function (pt) {
                //                     return [pt[0], '10%'];
                //                 }
                //             },
                //             textStyle: {
                //                 color: "black",
                //                 fontSize: 16
                //             },
                //             backgroundColor: "#fff",
                //             legend: {
                //                 bottom: 'bottom',
                //                 data: legendList,
                //                 padding: [0,200,0, 200],
                //             },
                //             xAxis: {
                //                 type: 'time',
                //                 boundaryGap: false,
                //                 splitLine: {
                //                     show: true,
                //                     lineStyle: {
                //                         type: "dotted",
                //                         color: "black"
                //                     }
                //                 },
                //                 axisLabel: {
                //                     formatter: {
                //                         year: '{yearStyle|{yyyy}/{MM}}',
                //                         month: '{MMM}',
                //                         day: '{MM}/{dd}',
                //                         hour: '{HH}:{mm}',
                //                         minute: '{HH}:{mm}',
                //                         second: '{HH}:{mm}:{ss}',
                //                         millisecond: '{hh}:{mm}:{ss} {SSS}',
                //                         none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                //                     },
                //                     rich: {
                //                         yearStyle: {
                //                             // Make yearly text more standing out
                //                             color: '#000',
                //                             fontWeight: 'bold'
                //                         }
                //                     }
                //                 },
                //                 splitNumber: splitNumber
                //             },
                //             yAxis: {
                //                 type: 'value',
                //                 boundaryGap: [0, '100%'],
                //                 nameTextStyle: {
                //                     color: "black",
                //                     fontSize: 16,
                //                     fontWeight: "bold",
                //                 },
                //                 splitLine: {
                //                     show: true,
                //                     lineStyle: {
                //                         type: "dotted",
                //                         color: "black"
                //                     }
                //                 },
                //                 axisLabel: {
                //                     fontSize: 16,
                //                     fontWeight: "bold",
                //                 }
                //             },
                //             series: seriesList
                //         });
                //         await this.sleep(1 * 300);
                //         let img = null;
                //         if (this.isFinishOneChart) {
                //             img = await this.getChartImage(chart);
                //         }
                //         doc.addImage(img.src, "JPG", 0, startY200, 1760 / (scale * 1.5), 360 / (scale * 1.5));
                //         chart.clear();
                //         startY200 = startY200 + (360 / (scale * 1.75)) + marginBottom;
                //         this.showDialogPreview = false;
                // }
                var fileNamePdf = response.deviceName + "_" + this.formatDate(new Date());

                await doc.save(fileNamePdf, {
                    returnPromise: true
                });
                this.loading = false;
            } catch (e) {
                console.error("failed to export", e);
                this.loading = false;
            }
        },
        formatDate(date) {
            return [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
                date.getFullYear(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            ].join('');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        minChartTs(isDeviceTs, value) {
            return (isDeviceTs) ? ((this.displayScaleMin == 0 && this.displayScaleMax == 0) ? value.min : this.displayScaleMin) : undefined;
        },
        maxChartTs(isDeviceTs, value) {
            return (isDeviceTs) ? ((this.displayScaleMin == 0 && this.displayScaleMax == 0) ? value.max : this.displayScaleMax) : undefined;
        },
        convertStringToDateNumber(dateStr) {
            var d1 = dateStr.split(" ");
            var date = d1[0].split("/");
            var time = d1[1].split(":");
            var dd = parseInt(date[2]);
            var mm = parseInt(date[1] - 1);
            var yyyy = parseInt(date[0]);
            var hh = parseInt(time[0]);
            var min = parseInt(time[1]);
            var ss = parseInt(time[2]);
            var fromdt = +new Date(yyyy, mm, dd, hh, min, ss);
            return fromdt;
        },
        makeLegendName(dataChart) {
            var siteStationIdOther = dataChart.siteStationName;
            var dataNameOther = this.$i18n.locale === 'en' ? dataChart.nameEn : dataChart.nameJp;
            var chartNameOther = null;
            if (dataChart.channelNo != null && dataChart.channelName != null) {
                chartNameOther = dataChart.channelName + "_" + siteStationIdOther;
            } else if (dataChart.isDeviceTs) {
                chartNameOther = dataNameOther + "(" + `${dataChart.subStation}-${dataChart.subNumber}` + ")";
            } else {
                chartNameOther = siteStationIdOther + "_" + dataNameOther;
            }
            return chartNameOther;
        },
        getColor200ByDataCode(dataCode) {
            switch (dataCode) {
                case "vibration_value_max":
                    return "#ff9900";
                case "vibration_value_10":
                    return "#867456";
                case "vibration_value_50":
                    return "#ff00ff";
                case "vibration_value_90":
                    return "#0000ff";
                case "noise_value_max":
                    return "#ff9900";
                case "noise_value_5":
                    return "#867456";
                case "noise_value_50":
                    return "#ff00ff";
                case "noise_value_95":
                    return "#0000ff";
                default:
                    return "red";
            }

        },
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
            });
        },
        onFinishedChart() {
            this.isFinishOneChart = true;
        },
        getChartImage(chart) {
            return this.loadImage(chart.getDataURL());
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        InitValueDefault(numer) {
            let siteID = getCookieSiteUser();
            if (!siteID) {
                return;
            }
            this.getCbbSites(0)
            .then(response => {
            if (response.code === 0) {
                let allSiteData = [];
                let tempData = response.data ?? [];
                for (let i = 0; i < tempData.length; i++) {
                allSiteData.push({
                    value: tempData[i].id,
                    text: tempData[i].siteName
                })
                }
                this.SiteNameInput = allSiteData.find((ele) => ele.value == siteID).text;
                this.getKnsUserDetail(getRememberKCloudID()).then((response) => {
                if (response.code === 0) {
                    let selectedUser = response.data;
                    this.userNameLogin = selectedUser.userName;
                } else {
                    this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                }
                }).catch((error) => {
                    if(error.code != undefined)
                    this.commonNotifyVue(this.ERROR_CODES[error.code], 'warning');
                })
            } else {
                this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
            }
            })
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        isDevice200or300(deviceCode) {
            return deviceCode == 200 || deviceCode == 300;
        },
    },

}
</script>
<style lang="scss" scoped>
.chartac {
    background-image: url("../../assets/img/chart.png") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.chart_hidden {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
}

::v-deep {
    .exportGraph-previewChart {
        overflow: auto;
        width: 100%;
    }
}


</style>
