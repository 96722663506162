<template>
    <v-dialog v-model="showDialog" max-width="800px" persistent scrollable>
      <v-card>
        <v-card-title class="bg-gray-light">
          <span class="text-h5 label-black">{{ title }}</span>
          <v-btn @click.prevent="handleConfirmCloseModal" class="ml-auto" icon>
            <v-icon id="close-button">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="pt-0 pb-0">
              <div class="row m-1">
                <div class="col-xl-12 col-lg-12 col-12">
                  <div class="input-group input-group-sm">
                    <h6 class="input-group-addon mb-1 label-black">{{ $t("userShare.link") }}&nbsp;</h6>
                  </div>
                  <div class="input-group-append">
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-12">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-12">
                            <v-text-field :readonly="true" type="text"
                                v-model="shareLink" hide-details="auto" outlined dense
                                append-icon="mdi-content-copy"
                                @click:append="copyToClipboard"
                                >
                            </v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
  
              <div class="row m-1">
                <div class="col-xl-12 col-lg-12 col-12">
                  <div class="input-group input-group-sm">
                    <h6 class="input-group-addon mb-1 label-black">{{ $t("userShare.timeToLive") }}&nbsp;</h6>
                  </div>
                  <div class="input-group-append">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-12">
                        <div class="row">
                          <div class="col-xl-11 col-lg-11 col-11">
                            <v-menu ref="menu" v-model="menuFrom"
                                    :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field :readonly="true" v-model="fromFormatted"
                                              :rules="fromDateRules" append-icon="mdi-calendar"
                                              v-bind="attrs" v-on="on" hide-details="auto" outlined
                                              dense @click:append="menuFrom = true"
                                              @blur="dateFrom = parseDate(fromFormatted)">
                                </v-text-field>
                              </template>
                              <v-date-picker :allowed-dates="disableStartDates" v-model="dateFrom"
                                             no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuFrom = false">
                                  {{ $t("exportData.ok") }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                          <div class="row sign">
                            <div style="padding-left: 0px !important;text-align:center;margin-top: 5px;"
                               class="col-xl-1 col-lg-1 col-1">
                              <b style="font-size: 20px;">〜</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-12">
                        <div class="row justify-content-start">
                          <div class="col-12">
                            <v-menu ref="menu" v-model="menuTo" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field :readonly="true" v-model="toFormatted"
                                              :rules="toDateRules"
                                              append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                              hide-details="auto" outlined dense
                                              @click:append="menuTo = true"
                                              @blur="dateTo = parseDate(toFormatted)">
                                </v-text-field>
                              </template>
                              <v-date-picker :allowed-dates="disableEndDates" v-model="dateTo" no-title
                                             scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuTo = false">
                                  {{ $t("exportData.ok") }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- device code and station -->
              <div class="row m-1">
              </div>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-white mr-1" style="background-color: #3d76ad" text
                 @click="handleUpdateUserShare(event)">
            {{ $t("labels.systemUserManager.save") }}
          </v-btn>
          <v-btn class="bg-secondary text-white" text @click.prevent="handleConfirmCloseModal">
            {{ $t("commonPopup.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </template>
  
  <script>
  import { EventBus } from '@/utils/eventBus.js'
  import { mapActions } from 'vuex';
  import {
    getCookieSiteUser, setCurrentPageCode
  } from "@/utils/auth";
  import $ from 'jquery';
  
  export default {
      name: "ShareLinkModal",
      props: ['visible', 'titleModal', 'frameId', 'session'],
      data() {
          return {
              valid: false,
              loading: false,
              title: "",
              showDialog: false,
              menuFrom: false,
              menuTo: false,
              dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              fromFormatted: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              toFormatted: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              PAGE_CODE: 'CS_DS',
              requiredRule(value, label) {
                const errorMsg = [this.$t(label) + this.ERROR_CODES['IS_REQUIRED']];
                if ((!value && value !== 0) || value?.toString()?.trim().length === 0) {
                  return errorMsg
                } else {
                  return [];
                }
              },
              shareLink: "",
              temporaryToken: "",
              shareLinkType: 1
  
          }
      },
      computed: {
      },
    created() {
          console.log("frameId ", this.frameId);
          setCurrentPageCode(this.PAGE_CODE);
          EventBus.$on('handleOpenShareLinkModal' + this.frameId, (value) => {
              this.title = value.title;
              this.shareLinkType = value.shareLinkType;
              this.showDialog = value.flag;
              // this.$refs.form?.resetValidation();
          });
      },
      watch: {
          "$i18n.locale": async function () {
              this.getErrorCode("dashboard");
          },
          'dateFrom'(val) {
              this.fromFormatted = this.formatDate(this.dateFrom)
          },
          'dateTo'(val) {
              this.toFormatted = this.formatDate(this.dateTo)
          },
          'showDialog'(val) {
            if (val) {
              this.generateShareLink();
            }
          }
      },
      async beforeMount() {
          // import ERROR_CODES by language
          this.getErrorCode("dashboard");
      },
      methods: {
          ...mapActions("dashboard", {
            createUserShare: "createUserShare",
            updateUserShare: "updateUserShare"
          }),
          formatDate(date) {
              if (!date) return null
  
              const [year, month, day] = date.split('-')
              return `${year}/${month}/${day}`
          },
          parseDate(date) {
              if (!date) return null
  
              const [year, month, day] = date.split('/')
              return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
          },
          disableStartDates(val) {
              return val <= this.dateTo;
          },
          disableEndDates(val) {
              return val >= this.dateFrom;
          },
          copyToClipboard() {
            navigator.clipboard.writeText(this.shareLink).then(() => {
                this.snackbar = true;
                this.commonNotifyVue(this.ERROR_CODES['COPY_SUCCESS'], 'success');
            }).catch(err => {
                console.error('Error: ', err);
            });
          },
          handleUpdateUserShare() {
              this.$refs.form.validate()
              if (!this.$refs.form.validate()) {
                  return;
              }
              var request = {
                  siteId: getCookieSiteUser(),
                  temporaryToken: this.temporaryToken,
                  type: this.shareLinkType,
                  expiredStartDate: this.dateFrom,
                  expiredEndDate: this.dateTo
              }
              this.updateUserShare(request)
                  .then(response => {
                      if (response.code === 0) {
                          this.loading = false;
                          // this.showDialog = false;
                          $('#main-header').css("pointer-events", "");
                          // this.commonConfirmOKVue(this.ERROR_CODES['SAVE_OK']);
                          this.commonNotifyVue(this.ERROR_CODES['UPDATED_LINK_SUCCESS'], 'success');
                      } else {
                          this.loading = false;
                          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                      }
                  })
                  .catch(err => {
                      this.loading = false;
                      console.log(err);
                      if(err.code != undefined)
                        this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                  })
                  .finally(final => {
                      this.loading = false;
                      this.isSubmitAction = false;
                  });
  
          },
          generateShareLink() {
              var siteId = getCookieSiteUser();
              this.createUserShare({siteId: siteId, type: this.shareLinkType, session: this.session})
                  .then(response => {
                      if (response.code === 0) {
                          console.log(response.data);
                          this.shareLink = response.data?.shareLink;
                          this.temporaryToken = response.data?.temporaryToken;
                          this.shareLinkType = response.data?.type;
                          const startDateObject = response.data?.expiredStartDate.split("T")[0];
                          const endDateObject = response.data?.expiredEndDate.split("T")[0];
                          this.dateFrom = startDateObject;
                          this.dateTo = endDateObject;
                          this.fromFormatted = this.formatDate(this.dateFrom);
                          this.toFormatted = this.formatDate(this.dateTo);
                          this.loading = false;  
                      } else {
                          this.loading = false;
                          this.commonNotifyVue(this.ERROR_CODES[response.code], 'warning');
                      }
                  })
                  .catch(err => {
                      this.loading = false;
                      console.log(err);
                      if(err.code != undefined)
                        this.commonNotifyVue(this.ERROR_CODES[err.code], 'warning');
                  })
                  .finally(final => {
                      this.loading = false;
                      this.isSubmitAction = false;
                  });
          },
          handleConfirmCloseModal() {
            this.showDialog = false
            $('#main-header').css("pointer-events", "");
            this.handleResetFormItem();
          },
          
          handleResetFormItem() {
              this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              this.$refs.form.resetValidation();
          },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  
  
  .sign {
    margin-top: 3px;
  }
  .setting-export-checkBox {
      margin-right: 50px !important
    }
  .v-card__actions {
      border-top: 1px solid #ebebeb;
  }
  
  .label-black {
      color: black !important;
  }
  
  .checkboxLabel {
      margin-top: 8px !important;
      font-weight: 100;
      color: black;
  }
  .radioLabel {
      margin-top: 8px !important;
      font-weight: 100;
      color: black;
  }
  
  
  ::v-deep{
    .v-dialog {
      box-shadow: none !important;
    }
    .v-card {
      margin-top: 2rem !important;
    }
    @media only screen and (min-width:768px) {
      .sign {
        margin-left: 2px !important;
      }
    }
    @media only screen and (max-width: 500px) and (max-height: 1000px) and (orientation: portrait){
    .v-card {
      margin-top: 5rem !important;
    }
    .setting-export-checkBox {
      margin-right: 0px !important
    }
    .v-dialog > .v-card > .v-card__text {
      padding: 0 0 0 0 !important;
    }
  }
    @media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
      .v.card {
        margin-top: 4em !important
      }
    }
  }
  </style>
  