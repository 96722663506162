<template>
  <div class="screen-share-container">
    <video ref="mainVideo" class="screen-video" autoplay muted/>
    <div v-if="isNoScreen && !loading" class="no-content">
        {{ $t("notification.noScreen") }}
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" style="color: #3d76ad !important;"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {OpenVidu} from "openvidu-browser";
import {isEmpty} from "lodash";

export default {
  name: "ScreenSharePage",
  data() {
    return {
      loading: false,
      // OpenVidu objects
      OV: undefined,
      session: undefined,
      subscribers: [],
      playing : true,
      myUserName: "K-Cloud user",
      latestStreamTime : 0
    }
  },
  computed : {
    mainStream() {
      if (!isEmpty(this.subscribers)) {
        return this.subscribers[0]
      }
      return undefined
    },
    isNoScreen(){
      return isEmpty(this.subscribers)
    }
  },
  created() {
    this.$route.query.session && this.joinSession()
  },
  methods: {
    ...mapActions("dashboard", {
      getRealtimeSingleFrameSCSData: "getRealtimeSingleFrameSCSData",
      createRealtimeSingleFrameSCSToken: "createRealtimeSingleFrameSCSToken",
      createRealtimeSingleFrameSCSData : "createRealtimeSingleFrameSCSData"
    }),
    joinSession() {
      this.loading = true
      // --- 1) Get an OpenVidu object ---
      this.OV = new OpenVidu();

      // --- 2) Init a session ---
      this.session = this.OV.initSession();

      // --- 3) Specify the actions when events take place in the session ---

      // On every new Stream received...
      this.session.on("streamCreated", ({stream}) => {
        if(stream.creationTime > this.latestStreamTime) {
          this.latestStreamTime = stream.creationTime
          const subscriber = this.session.subscribe(stream);
          this.subscribers = [subscriber]
        }
      });

      // On every Stream destroyed...
      this.session.on("streamDestroyed", ({stream}) => {
        const index = this.subscribers.indexOf(stream.streamManager, 0);
        if (index >= 0) {
          this.subscribers.splice(index, 1);
        }
      });

      this.session.on("reconnected", () => {
        console.log("Reconnected")
      })

      // On every asynchronous exception...
      this.session.on("exception", (event) => {
        if (event.name === 'ICE_CONNECTION_FAILED') {
          const stream = event.origin;
          console.warn('Stream ' + stream.streamId + ' broke!');
          console.warn('Reconnection process automatically started');
        }
        if (event.name === 'ICE_CONNECTION_DISCONNECTED') {
          const stream = event.origin;
          console.warn('Stream ' + stream.streamId + ' disconnected!');
          console.warn('Giving it some time to be restored. If not possible, reconnection process will start');
        }
      });

      // --- 4) Connect to the session with a valid user token ---
      const session = "share_screen_" + this.$route.query.session.replaceAll(".", "_");
      this.getToken(session)
        .then((token) => {
          this.session.connect(token, {clientData: this.myUserName}).then(() => {
            this.loading = false
          })
            .catch((error) => {
              this.loading = false
              console.log("There was an error connecting to the session:", error.code, error.message);
            });
        })
        .catch(() => {
          this.createTokenCreateSession(this.$route.query.session)
            .then((response) => {
              this.session.connect(response, {clientData: this.myUserName}).then(() => {
                this.loading = false
              })
                .catch((error) => {
                  this.loading = false
                  console.log("There was an error connecting to the session:", error.code, error.message);
                });
            })
            .catch((e) => {
              //do something here when no screen sharing
              this.loading = false
              console.log(e)
            })
        })
    },
    leaveSession() {
      // --- 7) Leave the session by calling 'disconnect' method over the Session object ---
      if (this.session) this.session.disconnect();

      // Empty all properties...
      this.session = undefined;
      this.subscribers = [];
      this.OV = undefined;

      // Remove beforeunload listener
      window.removeEventListener("beforeunload", this.leaveSession);
    },
    async getToken(mySessionId) {
      const sessionId = await this.getSession(mySessionId);
      return await this.createToken(sessionId.sessionId);
    },

    async createTokenCreateSession(mySessionId) {
      const sessionId = await this.createRealtimeSingleFrameSCSData(mySessionId);
      return await this.createToken(sessionId.sessionId);
    },

    async getSession(sessionId) {
      return await this.getRealtimeSingleFrameSCSData(sessionId);
    },

    async createToken(sessionId) {
      return await this.createRealtimeSingleFrameSCSToken(sessionId);
    },
    initVideo() {
      if (this.mainStream) {
        this.playing = false
        this.mainStream.addVideoElement(this.$refs.mainVideo)
        return
      }
      this.playing = true
    },
    playVideo() {
      this.$refs.mainVideo.play()
      this.playing = true
    },
  },
  watch : {
    "mainStream"() {
      this.initVideo()
    },
  },
  destroyed() {
    this.leaveSession()
  },
};
</script>
<style lang="scss" scoped>
.screen-video{
  width: 100%;
}
.cussss{
  background: red;
}

.no-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2rem;
}
</style>
