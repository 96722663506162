<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <button v-if="!isGuestSite" @click="openViewSensor(1)" type="button" class="btn button-tooltip bg-white mr-2" v-bind="attrs"
                    v-on="on" style="width: 35px;height: 35px;">
                    <i class="fas fa-minus" style="visibility: hidden"></i>
                </button>
            </template>
            <span class="justify-content-end align-content-end" style="font-size: 1.2rem">{{ $t("tooltip.measurementList") }}</span>
        </v-tooltip>
        <ViewDataSensorModal :frameId="frameId" @close="showModalSetting = false" />
    </span>
</template>
<script>
import ViewDataSensorModal from "@/modals/ViewData/ViewDataSensorModal";
import { EventBus } from "@/utils/eventBus";
import $ from 'jquery';
import { getRoleID } from "@/utils/auth";
export default {
    props: ['frameId'],
    data() {
        return {
            showModalSetting: false
        }
    },
    computed: {
        isGuestSite() {
            return getRoleID() == 4;
        }
    },
    components: {
        ViewDataSensorModal
    },
    methods: {
        openViewSensor(numer) {
            this.editedItem = {}
            this.modelCreate = true
            EventBus.$emit('handleOpenViewSensor' + this.frameId, {
                title: this.$t('viewDataSensor.title'),
                flag: true
            });
            $('#main-header').css("pointer-events", "none");
            this.showModalSetting = true;
        },
    },

}
</script>
<style scoped>
.button-tooltip {
  background-image: url("../../assets/img/info.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>
