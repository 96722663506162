<template>
  <div v-if="!isGuestSite" class="text-center m-2 mr-1" >
    <v-menu
      content-class="notification"
      v-model="isShow"
      rounded
      offset-y
      right
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          v-if="isShowCountNotify"
          bordered
          color="error"
          icon="mdi-comment-alert-outline"
          overlap
        >
          <v-icon v-bind="attrs" v-on="on" color="white">mdi-bell-ring</v-icon>
        </v-badge>
        <v-badge color="transparent" v-else>
          <v-icon v-bind="attrs" v-on="on" color="white">mdi-bell-ring</v-icon>
        </v-badge>
      </template>
      <v-card
        v-if="!isLoading && notifications.length > 0"
        elevation="16"
        width="420"
        ref="warning"
      >
        <v-virtual-scroll
          :bench="0"
          :items="notifications"
          key="id"
          height="500"
          width="420"
          item-height="80"
        >
          <template v-slot:default="{ item }">
            <v-list-item class="item pt-1" v-if="notifications" :key="item.index">
              <v-list-item-action>
                <v-btn fab small depressed color="primary">
                  {{ item.index }}
                </v-btn>
              </v-list-item-action>

              <v-list-item-content class="p-0">
                <v-list-item-title>
                  <strong>{{ item.warningLevel }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>{{
                      item.deviceName + " | " +  item.siteStationName 
                    }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span>{{
                      item.dataName
                    }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span>{{
                      commonFormatStrToDate(item.occurrenceTime, 7)
                    }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon small color="orange"> mdi-alert</v-icon>
                {{ item.data }} {{ item.unit }}
              </v-list-item-action>
            </v-list-item>
            <!-- <v-divider></v-divider> -->
          </template>
        </v-virtual-scroll>
      </v-card>
      <v-card v-else-if="!isLoading && notifications.length === 0" min-width="200">
        <v-card-title>
          {{ $t("notification.noData") }}
        </v-card-title>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { GetNotifications } from "@/api/bell";
import { ResponseCode } from "@/constants/constants";
import { getCookieSiteUser } from "@/utils/auth";
import { getRoleID } from "@/utils/auth";

export default {
  components: {},
  name: "NotificationsDropDown",
  data() {
    return {
      isShowCountNotify: true,
      isShow: false,
      isLoading: false,
      notifications: [],
    };
  },
  watch: {
    isShow: function () {
      if (this.isShow) {
        this.getBellList();
        this.isShowCountNotify = false;
      }
    },
  },
  computed: {
    isGuestSite() {
        return getRoleID() == 4;
    }
  },
  created() {
  },
  mounted() {
    if(this.$refs.warning != undefined)
      this.$refs.warning.focus();
  },
  methods: {
    getBellList() {
      this.isLoading = true;
      const request = {
        SiteId: getCookieSiteUser(),
        Limit: 100,
        Language: this.$i18n.locale,
      };
      GetNotifications(request)
        .then((response) => {
          if (response.data && response.data.code === ResponseCode.SUCCESS) {
            this.notifications = response?.data?.bells;
          } else {
            this.commonNotifyVue(this.ERROR_CODE[response.code], "warning");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if(err.code != undefined)
            this.commonNotifyVue(this.ERROR_CODE[err.code], "warning");
        })
        .finally((final) => {
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.notification {
  transform: translateX(-100%);
  position: fixed;
  top: 50px !important;
}
@media screen and (max-width: 720px) {
  .notification {
    top : 100px !important;
  }
}
</style>

<style lang="scss">
.navbar-badge {
  font-size: 0.9rem;
  font-weight: 300;
  padding: 2px 4px;
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: red;
  color: white;
}

#hoverDiv:hover {
  background-color: #0c84ff;
  color: white;
}

.item {
  border-bottom: 1px solid #ececec !important;
  margin: auto;
}
</style>
