import router from '../router/router';
import {
  cookiesGetI18Lang,
  cookiesSetI18Lang,
  setCurrentPageCode,
  getCookieSiteUser,
  getToken,
  setRememberKCloudPass,
  setRememberKCloudID,
  setRememberMe,
  getRememberKCloudPass,
  getRememberKCloudID,
  getRememberMe,
  setCookieUserType,
  setMenuAccesses,
  setCookieSiteUser,
  setCookiePrivileges,
  removeCookiePrivileges,
  removeCookieSiteUser,
  removeCookieUserType,
  removeCurrentPageCode,
  removeMenuAccesses,
  removeToken,
  cookiesRemoveI18Lang,
  getCookieUserType,
  getCookiePrivileges,
  setRoleID,
  removeRoleID
} from "@/utils/auth";
import { verifyTokenUserShare } from "@/api/dashboard";
import { setToken } from "@/utils/auth";


const whiteList = ['/login', '/expiredlink', '/notfound']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith("/dashboard/temporary_token") || (to.path.startsWith("/screenShare") && to.query?.temporary_token)) {
    const payload = { temporaryToken: to.params?.token || to.query?.temporary_token };
    
    try {
      const response = await verifyTokenUserShareByToken(payload);
      
      if (response.code === 0) {
        setRememberKCloudID(response.data['userDetail']['id']);
        setCookieSiteUser(response.data['userDetail']['siteId']);
        setMenuAccesses(1);
        var dashboardMenu = response.data['userDetail']['privileges']['privileges'].filter(x => x.code == 'CS_DA');
        setCookiePrivileges(dashboardMenu);
        setCookieUserType(response.data['userDetail']['userType']);
        setRoleID(response.data['userDetail']['roleId'])
        return next();
      } else {
        removeToken();
        removeMenuAccesses();
        removeCookieUserType();
        removeCookieSiteUser();
        removeCurrentPageCode();
        removeCookiePrivileges();
        cookiesRemoveI18Lang();
        removeRoleID();
        if (response.code == "LINK_SHARE_NOTFOUND") {
          return next({ path: '/notfound' });
        } else if (response.code == "LINK_SHARE_EXPIRED") {
          return next({ path: '/expiredlink' });
        }
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      return next({ path: '/login' });
    }
  }
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({path: '/admin'})
    } else {
      if (to.path === '/login' || to.path === '/admin') {
        next();
      } else {
        const currentUSer = getCookieUserType();
        const menuAccess = getCookiePrivileges();

        const whiteListRouter = ['/notfound', '/camera', '/screenShare', '/expiredlink'];

        if (parseInt(currentUSer) !== 1) {
          if (menuAccess !== undefined && menuAccess !== null) {
            JSON.parse(menuAccess).filter(value => {
              whiteListRouter.push(value.path);
              if (value.children !== undefined && value.children.length > 0) {
                value.children.forEach(child => {
                  whiteListRouter.push(child.path);
                })
              }
            });
            if (whiteListRouter.includes(to.path)) {
              return next();
            } else {
              return next({path: '/notfound'})
            }
          }
        } else {
          next();
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({path: '/login'})
    }
  }
  // next();
});

router.afterEach(() => {
});

async function verifyTokenUserShareByToken(data) {
  const response = await verifyTokenUserShare(data);
  const result = response.data;

  if (result.code === 0) {
  setToken(result.token);
  }
  
  return result;
}
