import request from "../utils/request";

export function getRealtimeData(data) {
  return request({
    url: `/Dashboard/getRealtimeData/${data.siteId}`,
    method: "post",
    data: data.frameId,
    headers: {
      'PAGE-CODE': 'CS_DA'
    }
  });
}

export function getRealtimeSingleFrameData(data) {
  return request({
    url: `/Dashboard/getRealtimeSingleFrameData/${data.siteId}/${data.frameId}/${data.flag}`,
    method: "get",
    headers: {
      'PAGE-CODE': 'CS_DA'
    }
  });
}

export function getRealtimeSingleFrameGCData(data) {
  return request({
    url: `/Dashboard/getRealtimeSingleGCData/${data.siteId}/${data.frameId}`,
    method: "get",
    headers: {
      'PAGE-CODE': 'CS_DA'
    }
  });
}

export function getRealtimeSingleFrameSCSData(data) {
  return request({
    url: `https://videocallserver.kns-cloud.com/openvidu/api/sessions/${data}`,
    method: "get",
    headers: {
      'PAGE-CODE': 'CS_DA',
      'Authorization': 'Basic T1BFTlZJRFVBUFA6MTIzNDU2'
    }
  });
}

export function createRealtimeSingleFrameSCSToken(data) {
  return request({
    url: `https://videocallserver.kns-cloud.com/openvidu/api/sessions/${data}/connection`,
    method: "post",
    data : {},
    headers: {
      'PAGE-CODE': 'CS_DA',
      'Authorization': 'Basic T1BFTlZJRFVBUFA6MTIzNDU2'
    }
  });
}

export function getDashboardLayout(siteId) {
    return request({
        url: `/Dashboard/getDashboardLayout/${siteId}`,
        method: "get",
        headers: {
            'PAGE-CODE': 'CS_DA'
        }
    });
}

export function getSCData(data) {
  return request({
      url: `/Dashboard/getSCData/${data.siteId}/${data.frameId}`,
      method: "get",
      headers: {
          'PAGE-CODE': 'CS_DA'
      }
  });
}

export function getLRData(data) {
  return request({
      url: `/Dashboard/getLRData/${data.siteId}/${data.frameId}/${data.mode}`,
      method: "get",
      headers: {
          'PAGE-CODE': 'CS_DA'
      }
  });
}

export function getSafieListDevice() {
  return request({
      url: `/Dashboard/getListSafieDevice`,
      method: "get",
      headers: {
          'PAGE-CODE': 'CS_DA'
      }
  });
}
export async function createUserShare(payload) {
  return await request({
      url: "/UserShare/create",
      method: "post",
      data: payload,
      headers: {
          'PAGE-CODE': 'CS_DA'
      }
  });
}
export async function updateUserShare(payload) {
  return await request({
      url: "/UserShare/update",
      method: "post",
      data: payload,
      headers: {
          'PAGE-CODE': 'CS_DA'
      }
  });
}
export async function verifyTokenUserShare(payload) {
  return await request({
      url: "/UserShare/verifyToken",
      method: "post",
      data: payload,
      headers: {
          'PAGE-CODE': 'CS_DA'
      }
  });
}
